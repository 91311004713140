.App {
  text-align: center;
}

.App-logo {
  height: 55px;
  padding: 10px;
  pointer-events: none;
}

.App-header {
  background-color: #27292b;
  height: 8vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-home {
  background-color: #27292b;
  min-height: 12vh;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: white;
}

.App-404 {
  background-color: #27292b;
  min-height: 87vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.App-footer {
  background-color: #27292b;
  height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
}

.App-link {
  color: #61dafb;
}